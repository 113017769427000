let qualiproject = {
    menuToggle: function(){
        $('.menu__hamburger svg').click(function(){
            $('.menu__container').toggleClass('menu__container--active')
        })
        $('.menu__close svg').click(function(){
            $('.menu__container').toggleClass('menu__container--active')
        })

        $('.menu__container .overlay').click(function(){
            $('.menu__container').removeClass('menu__container--active')
        })

    },
    sliderTestimonials:function(){
        $('.testimonials__slick').slick({
            infinite: true,
            autoplay: true,
            prevArrow: '<button type="button" class="slick-prev"><svg data-name="Livello 1" id="Livello_1" viewBox="0 0 64 66" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;}</style></defs><title/><path d="M0,34A32,32,0,1,0,32,2,32,32,0,0,0,0,34Z"/><g data-name="Arrow Left" id="Arrow_Left"><path class="cls-1" d="M14,28L30.9,45.69a1.5,1.5,0,0,0,2.19,0L50,28"/></g></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg data-name="Livello 1" id="Livello_1" viewBox="0 0 64 66" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;}</style></defs><title/><path d="M0,34A32,32,0,1,0,32,2,32,32,0,0,0,0,34Z"/><g data-name="Arrow Left" id="Arrow_Left"><path class="cls-1" d="M14,28L30.9,45.69a1.5,1.5,0,0,0,2.19,0L50,28"/></g></svg></button>'

        })
    },
    inputHandler:function(masks, max, event) {
        var c = event.target;
        var v = c.value.replace(/\D/g, '');
        var m = c.value.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    },
    maskphone:function(){
        var telMask = ['(99) 9999-99999', '(99) 99999-9999'];
        var tel = document.querySelector('#formphone');
        VMasker(tel).maskPattern(telMask[0]);
        tel.addEventListener('input', qualiproject.inputHandler.bind(undefined, telMask, 14), false);
    },
    submenu:function(){
        $('.menu__dropdown').click(function(){
            $(this).toggleClass('menu__dropdown--active')
            $('.menu__submenu').slideToggle()
        })
    },
    menuFixed:function(){
        if(window.innerWidth > 768){
            $(window).scroll(function(){
                if($('body').scrollTop() > $('.header').height()){
                    $('header').addClass('header--fixed')
                    $('body').css('paddingTop',130)
                }else{
                    $('header').removeClass('header--fixed')
                }
            })
        }
        
    },
    toggleAnswer:function(){
        $('.perguntas__ask').click(function(){
            $('.perguntas__aswer').slideUp()
            $(this).next('.perguntas__aswer').slideToggle()
        })
    },
    scrollSuave:function(){
        $('.banner__cta[href^="#"]').on('click', function(e) {
            e.preventDefault();
            var id = $(this).attr('href'),
                    targetOffset = $(id).offset().top;
                    
            $('html, body').animate({ 
                scrollTop: targetOffset
            }, 500);
        });
    },
    whatsLink:function(){
        if($(window).width() > 768){
            $('.social__whatsapp').attr('href','https://web.whatsapp.com/send?phone=5511994963080')
        }else{
            $('.social__whatsapp').attr('href','https://wa.me/5511994963080')
        }

        $('.social__whatsapp').click(function(){
            qualiproject.gtag_whatsapp()
        })
    },
    gtag_whatsapp: function(){
        var callback = function () {
            if (typeof(url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-832698327/mrZACO6izfsBENfvh40D',
            'event_callback': callback
        });
        
        return false;
    },
    init: function(){
        this.menuToggle()
        this.submenu()
        this.sliderTestimonials()
        this.maskphone()
        // this.menuFixed()
        this.toggleAnswer()
        // this.scrollSuave()
        this.whatsLink()

    }
}
qualiproject.init()
